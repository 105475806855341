import Swiper from 'swiper/bundle';

window.addEventListener('DOMContentLoaded', function () {
  const postCarousels = document.querySelectorAll('.posts-carousel');
  const desktopCarousels = document.querySelectorAll('.posts-carousel-desktop');

  if (0 < postCarousels.length) {
    postCarousels.forEach(function (postCarousel) {
      const args = {
        slidesPerView: 1,
        watchOverflow: true,
        loop: true,

        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
        },

        navigation: {
          prevEl: postCarousel.querySelector('.swiper-button-prev'),
          nextEl: postCarousel.querySelector('.swiper-button-next'),
        },
      };

      new Swiper(postCarousel, args);
    });
  }

  if (0 < desktopCarousels.length) {
    desktopCarousels.forEach((desktopCarousel) => {
      new Swiper(desktopCarousel, {
        slidesPerView: 1,
        loop: true,
        navigation: {
          prevEl: desktopCarousel.querySelector('.swiper-button-prev'),
          nextEl: desktopCarousel.querySelector('.swiper-button-next'),
        },
      });
    });
  }
});
